import { type JSX } from "preact/compat";

import "./VariantSelector.scss";
import {
  getFormattedPrice,
  prices,
  selectFirstCardInSet,
  selectedPack,
  type SetsType,
} from "../model";
import { useComputed, useSignalEffect } from "@preact/signals";

type Pack = {
  pack2: PackInner;
  pack3: PackInner;
};

type PackInner = {
  caption: string;
  title: string;
};
interface Props {
  pack: Pack;
}

export const VariantSelector = ({ pack }: Props) => {
  const handleChange: JSX.GenericEventHandler<HTMLInputElement> = (e) => {
    selectedPack.value = +e.currentTarget.value as unknown as SetsType;
  };

  useSignalEffect(selectFirstCardInSet);

  const set3Price = useComputed(() => getFormattedPrice(prices[3].price));
  const set3OldPrice = useComputed(() => {
    if (
      !prices[3].oldPrice.value ||
      prices[3].oldPrice.value === prices[3].price.value
    )
      return "";
    return getFormattedPrice(prices[3].oldPrice);
  });

  const set2Price = useComputed(() => getFormattedPrice(prices[2].price));
  const set2OldPrice = useComputed(() => {
    if (
      !prices[2].oldPrice.value ||
      prices[2].oldPrice.value === prices[2].price.value
    )
      return "";
    return getFormattedPrice(prices[2].oldPrice);
  });

  return (
    <div class="selector">
      <fieldset class="selector__pack">
        <input
          class="selector__pack-input"
          type="radio"
          name="pack"
          value={3}
          id={"pack3"}
          onChange={handleChange}
          defaultChecked
          aria-label={pack.pack3.title}
        />
        <label htmlFor={"pack3"} class="selector__pack-label">
          <span class="selector__pack-name">{pack["pack3"].title}</span>
          <div class="selector__pack-caption">{pack["pack3"].caption}</div>
          <div class="selector__pack-prices skeleton skeleton_sm">
            <div className="skeleton__bg skeleton_sm"></div>
            <span class="selector__pack-price" id={`${"pack3"}-price`}>
              {set3Price}
            </span>
            <span class="selector__pack-oldprice" id={`${"pack3"}-old-price`}>
              {set3OldPrice}
            </span>
          </div>
        </label>

        <input
          class="selector__pack-input"
          type="radio"
          name="pack"
          value={2}
          id={"pack2"}
          onChange={handleChange}
          aria-label={pack.pack2.title}
        />
        <label htmlFor={"pack2"} class="selector__pack-label">
          <span class="selector__pack-name">{pack["pack2"].title}</span>
          <div class="selector__pack-caption">{pack["pack2"].caption}</div>
          <div class="selector__pack-prices skeleton skeleton_sm">
            <div className="skeleton__bg skeleton_sm"></div>
            <span class="selector__pack-price" id={`${"pack2"}-price`}>
              {set2Price}
            </span>
            <span class="selector__pack-oldprice" id={`${"pack2"}-old-price`}>
              {set2OldPrice}
            </span>
          </div>
        </label>
      </fieldset>
    </div>
  );
};
